import {createSlice} from "@reduxjs/toolkit";

const requirementsSlice = createSlice({
    name: "requirements",
    initialState: {
        requirements: [],
        selectedProjects: [],
        requirementItems: [],
        filterDateRange: [],
    },
    reducers: {
        setRequirements(state, action) {
            state.requirements = action.payload;
        },
        setRequirementItem(state, action) {
            const {RequirementId = 0} = action.payload;

            let items = state?.requirementItems;
            items[RequirementId] = action.payload;
            state.requirementItems = items;
        },
        setFilterDateRange(state, action) {
            state.filterDateRange = action.payload
        }
    },
});

export const {
    setRequirements,
    setRequirementItem,
} = requirementsSlice.actions;

export default requirementsSlice.reducer;
