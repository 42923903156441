import {Modal} from "antd";
import {loadLabourLines, resetRatesLabourLines} from "../../../../services/labour";

const doResetRates = ({selected, accountId, timeZoneId, refreshSelection}) => {
    Modal.confirm({
        content: "Are you sure you want to reset all rates to the defaults from each position?",
        okText: "Yes, Reset Rates",
        async onOk() {
            await resetRatesLabourLines(selected.map((i) => i.Id));
            await loadLabourLines(accountId, timeZoneId);
            refreshSelection();
        },
        onCancel() {
        },
    });
};
export {doResetRates};
