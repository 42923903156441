import React, {useCallback, useEffect, useRef, useState} from "react";
import _ from "lodash";
import {Divider, Form, message, Switch} from "antd";
import {ApiProjectType, NewRecordState, TagType} from "../../../constants/constants";
import {errorFromHttpResponse} from "../../../helpers/error";
import {InputWithFormItemWithRef, SelectWithFormItem, TextAreaWithFormItem} from "../../Common/Input";
import {useDispatch, useSelector} from "react-redux";

import {createProjectColorTagRendered, mapProjectWithBadgeToTag, mapTagToOption} from "../../../helpers/Tags";

import {createOrUpdate, refreshAttachments} from "../../../services/attachments";
import {continueEdit} from "../../../redux/reducers/detailsPanel";
import TagsSelect from "../../Common/Selects/TagsSelect";
import AuditFormItem from "../../Common/AuditFormItem";

const AllProjectOption = {value: "— All Projects —", label: "— All Projects —"};

const getSelectedProjects = (data) =>
    data?.IsAllProjects
        ? [AllProjectOption.value]
        : (data?.Projects || []).map((p) => (_.isNumber(p) ? p.toString() : p.Id.toString()));

const prepareFormData = (data) => {
    const formData = {
        ...data,
        SelectedProjects: getSelectedProjects(data),
        Categories: data.Categories.map((x) => x.AccountTagId.toString()),
        Departments: data.Departments.map((x) => x.AccountTagId.toString()),
    };

    return formData;
};

const isAllProjectsSelected = (selected) => selected.includes(AllProjectOption.value);

const getProjectsFromForm = (selected) =>
    isAllProjectsSelected(selected) ? [] : selected.map((s) => parseInt(s, 10)).filter((s) => s);

const AttachmentDetailsPanel = ({type}) => {
    const itemToEdit = useSelector((state) => state.detailsPanel.item);

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const nextInputRef = useRef();
    const firstInputRef = useRef();

    const [editItemState, setEditItemState] = useState(NewRecordState.Empty);
    const [availableProjects, setAvailableProjects] = useState([]);

    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const projects = useSelector((state) => state.projects.projects).filter((p) => p.State !== ApiProjectType.Archive);

    const valueProjectIds = itemToEdit?.Projects?.map((p) => p.Id) || [];
    const archivedProjects = useSelector((state) => state.projects.projects).filter(
        (p) => p.State === ApiProjectType.Archive && valueProjectIds.includes(p.Id)
    );

    const categories = useSelector((state) => state.tags.categories || []);

    const departments = useSelector((state) => state.tags.departments || []);

    if (editItemState === NewRecordState.RequiredFieldsSubmitted) {
        setTimeout(() => nextInputRef.current.focus(), 100);
    }

    useEffect(() => {
        if (!itemToEdit) {
            setTimeout(() => firstInputRef.current.focus(), 200);
            return;
        }

        setEditItemState(NewRecordState.ReadyForEdit);

        const formData = prepareFormData(itemToEdit);
        form.setFieldsValue(formData);
    }, [itemToEdit, form]);

    const prepareAndUpdate = useCallback(
        (finishedForm) => {
            const updatePayload = {
                Id: itemToEdit?.Id,
                Name: finishedForm.Name,
                Link: finishedForm.Link,
                IsUrlEnabled: finishedForm.IsUrlEnabled,
                Description: finishedForm.Description,
                Departments: (finishedForm.Departments || []).map((x) => parseInt(x, 10)),
                Categories: (finishedForm.Categories || []).map((x) => parseInt(x, 10)),
                Projects: getProjectsFromForm(finishedForm.SelectedProjects || []),
                IsAllProjects: isAllProjectsSelected(finishedForm.SelectedProjects || []),
            };

            createOrUpdate(activePortfolio.Id, updatePayload)
                .catch((err) => {
                    const errorText = errorFromHttpResponse(err);
                    message.error(errorText);
                    console.error(err);
                })
                .then((responseId) => {
                    if (!itemToEdit?.Id) {
                        dispatch(continueEdit({item: {...updatePayload, Id: responseId}}));
                        setEditItemState(NewRecordState.ReadyForEdit);
                    }
                    return refreshAttachments();
                })
                .catch(console.error);

            if (!itemToEdit?.Id) {
                setEditItemState(NewRecordState.RequiredFieldsSubmitted);
            }
        },
        [itemToEdit?.Id, dispatch]
    );

    const onFinish = useCallback(
        (finishedForm) => {
            prepareAndUpdate(finishedForm);
        },
        [editItemState, prepareAndUpdate]
    );

    const save = useCallback(async () => {
        try {
            const values = await form.validateFields();
            onFinish(values);
        } catch (err) {
            console.error(err);
        }
    }, [form, onFinish]);

    useEffect(() => {
        if (isAllProjectsSelected(getSelectedProjects(itemToEdit))) {
            setAvailableProjects([AllProjectOption]);
        } else {
            setAvailableProjects([AllProjectOption, ...[...projects, ...archivedProjects].map(mapProjectWithBadgeToTag)]);
        }
    }, [itemToEdit]);

    const isOptionalFieldDisabled = editItemState !== NewRecordState.ReadyForEdit;

    return (
        <div className="side-panel">
            <Form
                form={form}
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                layout="horizontal"
                size="middle"
                initialValues={{IsUrlEnabled: true}}>
                <Divider orientation="left" className="divider-first">
                    Attachment Info
                </Divider>

                <InputWithFormItemWithRef
                    ref={firstInputRef}
                    name="Name"
                    label="Name"
                    hasFeedback
                    rules={[{required: true, message: "Name"}]}
                    onChanged={save}
                    placeholder="Enter a name to create new Attachment"
                />
                <InputWithFormItemWithRef
                    ref={nextInputRef}
                    name="Link"
                    disabled={isOptionalFieldDisabled}
                    label="URL"
                    onChanged={save}
                    placeholder="Enter an URL"
                />
                <Form.Item label="URL Enabled" name="IsUrlEnabled" valuePropName="checked"
                           className="ant-form-item-without-validation">
                    <Switch disabled={isOptionalFieldDisabled} onChange={save}/>
                </Form.Item>

                <SelectWithFormItem
                    label="Projects"
                    name="SelectedProjects"
                    disabled={isOptionalFieldDisabled}
                    tagRender={createProjectColorTagRendered(projects, true)}
                    showSearch={true}
                    style={{width: "100%"}}
                    mode="tags"
                    placeholder="Select a Project"
                    options={availableProjects}
                    onChanged={save}></SelectWithFormItem>
                
                <TagsSelect
                    tagType={TagType.Category}
                    disabled={isOptionalFieldDisabled}
                    label="Categories"
                    name="Categories"
                    mode="multiple"
                    placeholder="Select categories"
                    options={categories.map(mapTagToOption)}
                    save={save}
                    form={form}
                />
                <TagsSelect
                    tagType={TagType.Department}
                    disabled={isOptionalFieldDisabled}
                    label="Departments"
                    name="Departments"
                    mode="multiple"
                    placeholder="Select departments"
                    options={departments.map(mapTagToOption)}
                    save={save}
                    form={form}
                />

                <TextAreaWithFormItem
                    label="Details"
                    disabled={isOptionalFieldDisabled}
                    name="Description"
                    onChanged={save}
                    placeholder="Enter details"
                    autoSize={{minRows: 2}}
                ></TextAreaWithFormItem>
                {itemToEdit && (
                    <>
                        <AuditFormItem item={itemToEdit} label="Created" timeProp="CreatedAt" userProp="CreatedBy"/>

                        <AuditFormItem item={itemToEdit} label="Last Updated" timeProp="UpdatedAt"
                                       userProp="UpdatedBy"/>
                    </>
                )}
            </Form>
        </div>
    );
};

export default AttachmentDetailsPanel;
