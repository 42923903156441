import React, {useEffect, useRef, useState} from "react";
import {Col, Form, message, Modal, Row, Select} from "antd";
import {BulbFilled} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {geTagsGroup} from "../../../helpers/Tags";
import {formatContactName} from "../../../helpers/text";
import {moneyFormatter, moneyParser} from "../../../helpers/money";
import {InputNumberWithFormItem, InputWithFormItem, SelectWithFormItem, TextAreaWithFormItem} from "../../Common/Input";
import {quickCreateResource, refreshCollections, refreshResources} from "../../../services/resources";
import {refreshRequirements} from "../../../services/requirements";
import ContactSelect from "../../Common/Selects/ContactSelect";
import {loadTags} from "../../../services/tags";
import {loadContacts} from "../../../services/contacts";
import {ScreenType, TagType} from "../../../constants";
import TagsSelect from "../Selects/TagsSelect";

const screenReloaders = Object.freeze({
    [ScreenType.Requirements]: refreshRequirements, [ScreenType.Collections]: refreshCollections,
});

const CreateResourceModal = ({visible, onClose, refreshType = ScreenType.Requirements}) => {
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [contactId, setContactId] = useState();

    const form = useRef();

    const departments = useSelector((state) => state.tags.departments || []);
    const allContacts = useSelector((state) => state.contacts.contacts || []);

    const disabledContactIds = allContacts.filter((с) => !с.ContactId).map((c) => c.ContactId);

    const reloaders = [refreshResources, screenReloaders[refreshType]];

    useEffect(() => {
        if (!activePortfolioId) {
            return;
        }

        if (!dataLoaded) {
            Promise.all([loadTags(), loadContacts(activePortfolioId)]).then(() => setDataLoaded(true));
        }
    }, [activePortfolioId, dataLoaded, departments, allContacts]);

    const onOk = async () => {
        const values = await form.current.validateFields();

        const contact = allContacts.find((c) => c.Id === values.ContactId);

        const payload = {
            AccountId: activePortfolioId,

            Name: values.Name,
            Description: values.Description,
            DepartmentTag: (values.DepartmentTag || []).map((x) => parseInt(x, 10))[0],

            Source: contact && {
                ContactId: values.ContactId,
                Name: formatContactName(contact),
                Quantity: values.Quantity,
                Cost: values.Cost,
                Unit: values.Unit,
            },
        };

        setConfirmLoading(true);

        quickCreateResource(payload).then((response) => {
            message.success(`${payload.Name} has been created and added to the Resources Screen.`);
            Promise.all(reloaders).then(() => {
                setConfirmLoading(false);
                onClose({ResourceId: response.Id});
                reset();
            });
        });
    };

    const reset = () => {
        form.current.resetFields();
        setContactId(null);
    };

    const onCancel = () => {
        reset();
        onClose();
    };

    const onChangeDepartment = () => {
        const list = form.current.getFieldValue("DepartmentTag");
        const tag = list.pop();
        form.current.setFieldsValue({DepartmentTag: tag ? [tag] : []});
    };

    const sourceDisabled = !contactId;
    return (<>
        <Modal
            title={<>
                <BulbFilled/> &nbsp; Quick-Create Item
            </>}
            visible={visible}
            maskClosable={false}
            confirmLoading={confirmLoading}
            onOk={onOk}
            onCancel={onCancel}
            onClose={onClose}>
            <Form
                ref={form}
                name="quick-create-req"
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                layout="horizontal"
                size="middle"
                initialValues={{Unit: "Item"}}>
                <InputWithFormItem
                    name={["Name"]}
                    label={"Item"}
                    hasFeedback
                    rules={[{required: true, message: "Item is required!"}]}
                    placeholder="Enter an item name"
                />

                <TagsSelect
                    tagType={TagType.Department}
                    label={"Department"}
                    name={"DepartmentTag"}
                    placeholder="Select a department"
                    save={onChangeDepartment}
                    form={form.current}
                    closeOnChange={true}
                    groups={[geTagsGroup("All Departments", departments)]}
                />

                <TextAreaWithFormItem name={["Description"]} label={"Details"} autoSize={{minRows: 2}}
                                      placeholder="Enter details"/>

                <Form.Item name={["ContactId"]} label={"Source"} className="ant-form-item-without-validation">
                    <ContactSelect
                        placeholder="Select a source"
                        onChange={(v) => {
                            setContactId(v);
                        }}
                        onChanged={() => {
                        }}
                        disabled={false}
                        disabledContactIds={disabledContactIds}
                        hideQuickCreate={true}
                    />
                </Form.Item>

                <InputNumberWithFormItem
                    disabled={sourceDisabled}
                    name="Quantity"
                    label={"Inventory"}
                    formatter={(value) => (value || "0")?.toString()}
                    parser={(value) => parseInt(value, 10)}
                    min={0}
                />

                <Row>
                    <Col span={12}>
                        <InputNumberWithFormItem
                            disabled={sourceDisabled}
                            name="Cost"
                            label={"Default Cost"}
                            formatter={moneyFormatter}
                            parser={moneyParser}
                            min={0}
                            formItemProps={{
                                labelCol: {span: 16},
                                wrapperCol: {span: 4},
                                className: "ant-form-item-without-validation",
                            }}
                        />
                    </Col>
                    <Col span={10}>
                        <SelectWithFormItem
                            disabled={sourceDisabled}
                            label={"    "}
                            name={["Unit"]}
                            style={{width: "90px"}}
                            formItemProps={{
                                colon: false,
                                labelAlign: "left",
                                labelCol: {span: 2},
                                wrapperCol: {span: 2},
                                className: "ant-form-item-without-validation",
                            }}>
                            <Select.Option key="Item" value="Item">
                                /Item
                            </Select.Option>
                            <Select.Option key="Day" value="Day">
                                /Day
                            </Select.Option>
                        </SelectWithFormItem>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>);
};

export default CreateResourceModal;
