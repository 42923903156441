import {TagsFilled} from "@ant-design/icons";
import {Modal, Form, message} from "antd";
import React, {useState} from "react";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {TagType} from "../../../../constants/constants";
import {setTagToEdit} from "../../../../redux/reducers/tags";
import {loadTags, updateTag} from "../../../../services/tags";
import {InputWithFormItem, SelectWithFormItem} from "../../../Common/Input";
import {iconOptions} from "../../../../helpers/Tags";

const tagNames = {
    [TagType.Category]: "Category",
    [TagType.Department]: "Department",
    [TagType.Group]: "Group",
    [TagType.Role]: "Role",
    [TagType.TaskStatus]: "Status",
    null: "",
};

const EditTag = ({tagType}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const tagToEdit = useSelector((state) => state.tags.tagToEdit);
    const tags = {
        [TagType.Category]: useSelector((state) => state.tags.categories),
        [TagType.Department]: useSelector((state) => state.tags.departments),
        [TagType.Group]: useSelector((state) => state.tags.groups),
        [TagType.Role]: useSelector((state) => state.tags.roles),
        [TagType.TaskStatus]: useSelector((state) => state.tags.statuses),
    };
    const items = tags[tagType];

    const handleOk = async () => {
        let finishedForm;
        try {
            finishedForm = await form.validateFields();
        } catch (err) {
        }

        if (!finishedForm) return;

        try {
            setLoading(true);

            await updateTag({
                AccountTagId: tagToEdit.AccountTagId,
                Name: finishedForm.Name,
                Icon: finishedForm.Icon,
            });
            await loadTags();
            message.success(`${finishedForm.Name} was updated`);
            close();
        } catch (err) {
            message.error("Server error");
        } finally {
            setLoading(false);
        }
    };

    function close() {
        form.resetFields();
        dispatch(setTagToEdit(null));
    }

    const onChanged = () => {
    };

    useEffect(() => {
        if (tagToEdit) form.setFieldsValue({Name: tagToEdit.Name, Icon: tagToEdit.Icon});
    }, [tagToEdit?.AccountTagId]);

    if (!tagToEdit) return <></>;

    return (
        <Modal
            confirmLoading={isLoading}
            title={
                <>
                    <TagsFilled/> {tagToEdit.Name}
                </>
            }
            visible={true}
            maskClosable={false}
            onOk={handleOk}
            onCancel={close}
            okText="Save">
            <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                <InputWithFormItem
                    name="Name"
                    label={tagNames[tagType]}
                    hasFeedback
                    rules={[
                        {required: true, message: `Please input your ${tagNames[tagType].toLowerCase()}`},
                        {
                            validator: (_, value) =>
                                !items.find(
                                    (i) => i.AccountTagId !== tagToEdit.AccountTagId && i.Name.toLowerCase() === (value || "").toLowerCase()
                                )
                                    ? Promise.resolve()
                                    : Promise.reject(new Error(`This ${tagNames[tagType].toLowerCase()} already exists.`)),
                        },
                    ]}
                    onChanged={onChanged}
                    placeholder={`Enter a tag name`}
                />
                {tagType === TagType.TaskStatus && (
                    <SelectWithFormItem
                        allowClear
                        label="Icon (optional)"
                        name="Icon"
                        mode="single"
                        style={{width: "100%"}}
                        placeholder="Select an icon"
                        onChanged={onChanged}
                        options={iconOptions}></SelectWithFormItem>
                )}
            </Form>
        </Modal>
    );
};

export default EditTag;
