import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Col, Form, Row, Select} from "antd";
import {
    InputNumberWithFormItem,
    InputWithFormItem,
    SelectWithFormItem,
    TextAreaWithFormItem
} from "../../../Common/Input";
import ContactSelect from "../../../Common/Selects/ContactSelect";
import CreateContact from "../../../Common/QuickCreate/CreateContact";
import AuditFormItem from "../../../Common/AuditFormItem";
import {ApiContactType, TagType} from "../../../../constants/constants";
import {formatContactName} from "../../../../helpers/text";
import {moneyFormatter, moneyParser} from "../../../../helpers/money";
import {geTagsGroup} from "../../../../helpers/Tags";
import TagsSelect from "../../../Common/Selects/TagsSelect";

const InformationBlock = ({itemToEdit, form, save, isOptionalFieldDisabled, rulesets}) => {
    const [isQuickCreateContactVisible, setIsQuickCreateContactVisible] = useState(false);
    const [contactType, setContactType] = useState(ApiContactType.Space);

    const allContacts = useSelector((state) => state.contacts.contacts);
    const places = allContacts.filter((t) => t.Type === ApiContactType.Place);
    const departments = useSelector((state) => state.tags.departments || []);

    const onOpenCreatePopup = (type) => {
        setContactType(type);
        setIsQuickCreateContactVisible(true);
    };

    const trySetSource = (contactId, contact) => {
        if (!contact || !itemToEdit) return;

        form.setFieldsValue({Source: {Id: contactId, Name: formatContactName(contact)}});

        save();
    };

    const mapPlace = (place) => ({
        value: place.Id,
        label: formatContactName(place),
        contact: place,
    });

    const onChangeDepartment = () => {
        const list = form.getFieldValue("DepartmentTagId");
        const tag = list.pop();
        form.setFieldsValue({DepartmentTagId: tag ? parseInt(tag, 10) : null});
        save();
    };

    return (
        <>
            <InputWithFormItem
                name="Name"
                label={"Position"}
                rules={[{required: true, message: "Name"}]}
                onChanged={save}
                placeholder="Enter a position name"
                formItemProps={{className: "ant-form-item-without-validation"}}
            />
            <Form.Item name={["Source", "Id"]} label={"Source"} className="ant-form-item-without-validation">
                <ContactSelect
                    organizationsOnly={true}
                    allowClear={true}
                    placeholder="Select an organization"
                    selectedContactId={itemToEdit?.Source?.Id}
                    onChange={(v) => {
                    }}
                    onChanged={save}
                    disabled={isOptionalFieldDisabled}
                    onOpenCreatePopup={(type) => onOpenCreatePopup(type)}
                    hideQuickCreate={false}
                />
            </Form.Item>
            <TagsSelect
                tagType={TagType.Department}
                disabled={isOptionalFieldDisabled}
                label={"Department"}
                name={"DepartmentTagId"}
                placeholder="Select a department"
                save={onChangeDepartment}
                closeOnChange={true}
                form={form}
                getValueProps={(tagId) => ({value: tagId ? [tagId.toString()] : []})}
                groups={[geTagsGroup("All Departments", departments)]}
            />

            <Row>
                <Col span={12}>
                    <InputNumberWithFormItem
                        disabled={isOptionalFieldDisabled}
                        name="Cost"
                        label="Default Rate"
                        formatter={moneyFormatter}
                        parser={moneyParser}
                        controls={false}
                        formItemProps={{
                            labelCol: {span: 16},
                            wrapperCol: {span: 4},
                            className: "ant-form-item-without-validation",
                        }}
                        onChanged={save}
                    />
                </Col>
                <Col span={10}>
                    <SelectWithFormItem
                        disabled={isOptionalFieldDisabled}
                        label={"    "}
                        name={["Unit"]}
                        style={{width: "90px"}}
                        formItemProps={{
                            colon: false,
                            labelAlign: "left",
                            labelCol: {span: 2},
                            wrapperCol: {span: 2},
                            className: "ant-form-item-without-validation",
                        }}
                        onChanged={save}>
                        <Select.Option key="Hour" value="Hour">
                            /Hour
                        </Select.Option>
                        <Select.Option key="Flat" value="Flat">
                            Flat
                        </Select.Option>
                    </SelectWithFormItem>
                </Col>
            </Row>

            <SelectWithFormItem
                disabled={isOptionalFieldDisabled}
                label={"Ruleset"}
                name={["Ruleset", "Id"]}
                mode="single"
                showSearch
                style={{width: "100%"}}
                placeholder="Select a ruleset"
                closeOnChange={true}
                options={rulesets.map((r) => ({value: r.Id, label: r.Name}))}
                onChanged={save}></SelectWithFormItem>

            <TextAreaWithFormItem
                disabled={isOptionalFieldDisabled}
                name="Description"
                label={"Details"}
                placeholder="Enter details"
                autoSize={{minRows: 2}}
                onChanged={save}
            />

            <AuditFormItem item={itemToEdit} label="Created" timeProp="CreatedAt" userProp="Creator"/>

            <AuditFormItem item={itemToEdit} label="Last Updated" timeProp="UpdatedAt" userProp="Updater"/>

            <CreateContact
                form={form}
                apiContactType={contactType}
                isVisible={isQuickCreateContactVisible}
                isEmailRequired={true}
                onClose={() => {
                    setIsQuickCreateContactVisible(false);
                }}
                onContactCreated={trySetSource}
                places={places.map(mapPlace)}
                hideSpaces={true}
            />
        </>
    );
};

export default InformationBlock;
