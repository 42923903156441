import api from "../api";
import store from "../redux/store";
import {setWorkWeekSettings} from "../redux/reducers/workWeekSettings";
import {ApiPermissionLevel} from "../constants";

const {dispatch} = store;

export const loadWorkWeekSettings = () => {
    const activePortfolio = store.getState().projects.activePortfolio;

    if (!activePortfolio || !activePortfolio.Id || activePortfolio.PermissionLevel === ApiPermissionLevel.Instructor) {
        return;
    }

    return api.get(`api/WorkWeekSettings/GetByAccount?accountId=${activePortfolio?.Id}`)
        .then((response) => {
            dispatch(setWorkWeekSettings(response));
            return response;
        });
};

export const updateWorkWeekSettings = (payload) =>
    api.post(`api/WorkWeekSettings/Update`, payload, `Update_WorkWeekSettings_${payload?.Id}`);