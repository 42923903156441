import React, {useState, useEffect} from "react";
import moment from "moment";
import {useSelector, useDispatch} from "react-redux";
import DateRangeSelector from "../../../Common/Tables/FilterBar/DateRangeSelector";
import {getProjectEventDateRangeWithDefaults} from "../../../../services/timeline";
import {setDateRange} from "../../../../redux/reducers/dateRange";

const formatToISO = (date) => moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');

const DateSelector = ({setFilterHandler}) => {
    const [selectedItems, setSelectedItems] = useState();
    const dispatch = useDispatch();
    const {startDate, endDate} = useSelector((state) => state.dateRange);

    useEffect(() => {
        const dates = [moment(startDate), moment(endDate)];
        setSelectedItems(dates);
        applyFilter(dates);
    }, [startDate, endDate]);

    const setDateRangeAndDispatch = (startDate, endDate) => {
        startDate = formatToISO(moment(startDate).startOf('day'));
        endDate = formatToISO(moment(endDate).endOf('day'));
        dispatch(setDateRange({startDate, endDate}));
    };

    function applyFilter(filterItems) {
        if (filterItems.length === 0) {
            setFilterHandler(() => true);
        } else {
            const [filterStart, filterEnd] = filterItems;
            setFilterHandler((item) => {

                if (item.StartDateTime === null) return true;
                if (item.IsTimeOnly) return true;

                const itemStartDay = moment(item.StartDateTime).startOf("D");
                const itemEndDay = moment(item.EndDateTime).startOf("D");
                const filterStartDay = moment(filterStart).startOf("D");
                const filterEndDay = moment(filterEnd).startOf("D");

                if (itemStartDay < filterStartDay && itemEndDay < filterStartDay) return false;

                return itemStartDay <= filterEndDay;
            });
        }
    }

    return (<DateRangeSelector
        selectedItems={selectedItems}
        allItemsText="Select Dates"
        search
        onDateRangeChange={setDateRangeAndDispatch}
        fetchAllDatesRange={getProjectEventDateRangeWithDefaults}
    />);
};

export default DateSelector;