import React, {useState} from "react";
import _ from "lodash";
import {Button, Modal, Form, DatePicker} from "antd";
import {BulbFilled} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {batchUpdate} from "../../../../services/requirements";
import {useEffect} from "react";
import {setSelected} from "../../../../redux/reducers/mainTable";
import ContactSelect from "../../../Common/Selects/ContactSelect";
import useDateTimeFormat from "../../../../hooks/useDateTimeFormat";
import BulkEditableField, {isFieldDisabled} from "../../../Common/BulkEditableField/BulkEditableField";
import {
    createProjectColorTagRendered,
    mapProjectWithBadgeToTag,
    geTagsGroup, TagIcon,
} from "../../../../helpers/Tags";

import {SelectWithFormItem, TextAreaWithFormItem, InputWithFormItem} from "../../../Common/Input";
import {loadResourcesLookup} from "../../../../services/resources";
import {TagType} from "../../../../constants/constants";
import TagsSelect from "../../../Common/Selects/TagsSelect";

const Title = ({selected}) => (
    <>
        <BulbFilled/> {`Bulk Edit ${selected.length} Requirements`}
    </>
);

const BulkEdit = ({selected = []}) => {
    const [isVisible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const dateTimeFormats = useDateTimeFormat();
    const [[reqStartDate, reqEndDate], setRequirementDates] = useState([]);
    const [locationId, setLocationId] = useState();

    const departments = useSelector((state) => state.tags.departments || []);
    const projects = useSelector((state) => state.projects.activeProjects || []);
    const resources = useSelector((state) => state.resources.resourcesLookup || []);
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);
    const statuses = useSelector((state) => state.tags.statuses || []);

    const statusOptions = statuses.map((status) => ({
        value: status.AccountTagId, title: status.Name, label: (<>
            <TagIcon icon={status.Icon}/> {status.Name}
        </>),
    }));

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    function getRequirement(selected) {
        const requirement = _.uniq(selected.map((i) => i.Requirement.Name));

        if (requirement.length === 1) return requirement[0];

        return "";
    }

    function getProject(selected) {
        const projects = _.uniq(selected.map((i) => i.Project.Id.toString()));

        if (projects.length === 1) return [projects[0]];

        return [];
    }

    function getItem(selected) {
        const items = _.uniq(selected.map((i) => i.Resource?.Id));

        if (items.length === 1) return items[0];

        return null;
    }

    function getDepartment(selected) {
        const departments = _.uniq(selected.map((i) => i.Requirement.DepartmentTagId)).filter((x) => x);
        if (departments.length === 1) return [departments[0].toString()];

        return [];
    }

    function getLocation(selected) {
        const locations = _.uniq(selected.map((i) => i.Location?.Id));

        if (locations.length === 1) return locations[0];

        return null;
    }

    function getDetails(selected) {
        const notes = _.uniq(selected.map((i) => i.Requirement.Description));

        if (notes.length === 1) return notes[0];

        return "";
    }

    function getFormData(selected) {
        return {
            Project: getProject(selected),
            Name: getRequirement(selected),
            Department: getDepartment(selected),
            Location: getLocation(selected),
            Details: getDetails(selected),
            Item: getItem(selected),
        };
    }

    useEffect(() => {
        if (isVisible) setTimeout(() => form.setFieldsValue(getFormData(selected)), 100);
    }, [selected, form, isVisible]);

    useEffect(() => {
        loadResourcesLookup(activePortfolioId);
    }, [activePortfolioId]);

    function showModal() {
        setVisible(true);
    }

    function onOk() {
        const selectedRequirementIds = selected.map((i) => i.Requirement.Id);
        const formValue = form.getFieldsValue();

        setLoading(true);

        batchUpdate(
            selectedRequirementIds,
            {
                value: formValue.Project && formValue.Project[0],
                isUpdated: !isFieldDisabled("Project", form),
            },
            {
                value: formValue.Name,
                isUpdated: !isFieldDisabled("Name", form),
            },
            {
                value: {
                    Start: reqStartDate?.format(dateTimeFormats.universalDateFormat),
                    End: reqEndDate?.format(dateTimeFormats.universalDateFormat),
                },
                isUpdated: !isFieldDisabled("Dates", form),
            },
            {
                value: formValue.Location,
                isUpdated: !isFieldDisabled("Location", form),
            },
            {
                value: formValue.Department.length > 0 ? parseInt(formValue.Department[0], 10) : null,
                isUpdated: !isFieldDisabled("Department", form),
            },
            {
                value: formValue.Details,
                isUpdated: !isFieldDisabled("Details", form),
            },
            {
                value: formValue.Item,
                isUpdated: !isFieldDisabled("Item", form),
            },
            {
                value: formValue.StatusTagId,
                isUpdated: !isFieldDisabled("StatusTagId", form),
            }
        )
            .then((reqs) => {
                const newSelected = selected.map((s) => reqs.Items.find((r) => r.Requirement.Id === s.Requirement.Id));
                dispatch(setSelected(newSelected));
            })
            .finally(() => {
                setLoading(false);
                close();
            });
    }

    function onCancel() {
        close();
    }

    function close() {
        setVisible(false);
        form.resetFields();
    }

    function onChangeProject(list) {
        var project = list.pop();
        form.setFieldsValue({Project: project ? [project] : []});
    }

    function onChangeDepartment() {
        const list = form.getFieldValue("Department");
        var department = list.pop();
        form.setFieldsValue({Department: department ? [department] : []});
    }

    const onTagCreated = (newTag, tagType) => {
        if (tagType === TagType.Department) {
            form.setFieldsValue({
                Department: [newTag.AccountTagId.toString()],
            });
        }
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Edit
            </Button>
            {isVisible && (
                <Form form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} layout="horizontal" size="middle">
                    <Modal
                        confirmLoading={loading}
                        title={<Title selected={selected}/>}
                        visible={isVisible}
                        maskClosable={false}
                        onOk={onOk}
                        onCancel={onCancel}>

                        <BulkEditableField name="Project" form={form}>
                            {(name, isFieldDisabled) => (
                                <SelectWithFormItem
                                    label={name}
                                    name={name}
                                    mode="tags"
                                    disabled={isFieldDisabled}
                                    showSearch={true}
                                    rules={[{required: true, message: "Select a project"}]}
                                    tagRender={createProjectColorTagRendered(projects, false)}
                                    options={projects.map((p) => mapProjectWithBadgeToTag(p))}
                                    placeholder="Select a project"
                                    closeOnChange={true}
                                    onChanged={onChangeProject}></SelectWithFormItem>
                            )}
                        </BulkEditableField>

                        <BulkEditableField name="Name" form={form}>
                            {(name, isFieldDisabled) => (
                                <InputWithFormItem
                                    disabled={isFieldDisabled}
                                    label="Requirement"
                                    name={name}
                                    hasFeedback
                                    rules={[{required: true, message: "Name"}]}
                                    placeholder="Enter a requirement name"
                                />
                            )}
                        </BulkEditableField>

                        <BulkEditableField name="Dates" form={form}>
                            {(name, isFieldDisabled) => (
                                <Form.Item label={name}>
                                    <DatePicker.RangePicker
                                        format={dateTimeFormats.date}
                                        disabled={isFieldDisabled}
                                        onChange={(range) => setRequirementDates(range)}
                                        value={[reqStartDate, reqEndDate]}></DatePicker.RangePicker>
                                </Form.Item>
                            )}
                        </BulkEditableField>

                        <BulkEditableField name="Location" form={form}>
                            {(name, isFieldDisabled) => (
                                <Form.Item label={name} name={name}>
                                    <ContactSelect
                                        disabled={isFieldDisabled}
                                        selectedContactId={locationId}
                                        onChanged={(v) => {
                                        }}
                                        onChange={(v) => {
                                            setLocationId(v);
                                            form.setFieldsValue({Location: v});
                                        }}
                                        disabledContactTypes={[0, 1]}
                                        groupSpaces={true}
                                        onOpenCreatePopup={() => {
                                        }}
                                        hideQuickCreate={true}
                                    />
                                </Form.Item>
                            )}
                        </BulkEditableField>

                        <BulkEditableField name="Department" form={form}>
                            {(name, isFieldDisabled) => (
                                <TagsSelect
                                    tagType={TagType.Department}
                                    disabled={isFieldDisabled}
                                    label={"Department"}
                                    name={name}
                                    closeOnChange={true}
                                    placeholder="Select a department"
                                    save={onChangeDepartment}
                                    form={form}
                                    groups={[geTagsGroup("All Departments", departments)]}
                                />
                            )}
                        </BulkEditableField>

                        <BulkEditableField name="StatusTagId" form={form}>
                            {(name, isFieldDisabled) => (
                                <SelectWithFormItem
                                    allowClear={true}
                                    label="Status"
                                    name={name}
                                    showSearch={true}
                                    placeholder="Select a Status"
                                    options={statusOptions}
                                    disabled={isFieldDisabled}
                                />
                            )}
                        </BulkEditableField>

                        <BulkEditableField name="Details" form={form}>
                            {(name, isFieldDisabled) => <TextAreaWithFormItem disabled={isFieldDisabled} label={name}
                                                                              name={name}/>}
                        </BulkEditableField>

                        <BulkEditableField name="Item" form={form}>
                            {(name, isFieldDisabled) => (
                                <SelectWithFormItem
                                    label={name}
                                    name={name}
                                    mode="single"
                                    disabled={isFieldDisabled}
                                    showSearch
                                    options={resources.map((m) => ({value: m.Id, label: m.Name}))}
                                    placeholder="Select an item"
                                    allowClear></SelectWithFormItem>
                            )}
                        </BulkEditableField>
                    </Modal>
                </Form>
            )}
        </>
    );
};
export default BulkEdit;
