import React from "react";
import {Form, InputNumber, Row, Col} from "antd";
import './DurationShortcuts.scss';

const DurationShortcuts = ({timeShortcuts, handleBlur}) => {

    const sortedTimeShortcuts = [...timeShortcuts].sort((a, b) => a.DisplayOrder - b.DisplayOrder);

    return (
        <Form.Item
            label="Duration Shortcuts (hours):"
            className="form-item duration-shortcuts"
        >
            <Row gutter={[0, 8]} className="row">
                {sortedTimeShortcuts.map((shortcut, index) => {
                    return (
                        <Col
                            span={6}
                            key={index}
                            className="col"
                        >
                            <Form.Item
                                name={`durationShortcut${index}`}
                                initialValue={shortcut.Value}
                                className="row-margin"
                            >
                                <InputNumber
                                    min={0}
                                    controls={false}
                                    className="input-number"
                                    precision={2}
                                    defaultValue={shortcut.Value}
                                    formatter={value => `${value}`}
                                    parser={value => parseFloat(value)}
                                    onBlur={(e) => handleBlur(index, e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                    );
                })}
            </Row>
        </Form.Item>
    );
};

export default DurationShortcuts;