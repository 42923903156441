import React, {useState, useEffect} from "react";
import _ from "lodash";
import {Form, Divider, Switch, Checkbox, Col, Row, message, Tooltip, Popover} from "antd";
import {
    ApiFilterIncludeType, ApiFilterTaskType, ApiFilterCrewType, ApiFilterLayout, ApiFilterLayoutType
} from "../../../constants/constants";
import {useSelector} from "react-redux";
import {CopyOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import Team from "./Filters/Team";
import Statuses from "./Filters/Statuses";
import Dates from "./Filters/Dates";
import Departments from "./Filters/Departments";
import Categories from "./Filters/Categories";
import CrewOrLabourLine from "./Filters/CrewOrLabourLine";
import LayoutSingleSelector from "./Filters/Layout";
import GroupBySingleSelector, {scheduleGroupBy} from "./Filters/GroupBy";
import Locations from "./Filters/Locations";
import FilterLogic from "./Filters/FilterLogic";
import TimeZone from "./Filters/TimeZone";
import Columns, {columns} from "./Filters/Columns";
import IncludeBookings from "./Filters/IncludeBookings";
import usePermissions from "../../../hooks/usePermissions";
import ScheduleIgnoreProjects from "./Filters/ScheduleIgnoreProjects";

const DatesFilterName = "Dates";

export const ScheduleBlock = ({
                                  form,
                                  selectedProjects,
                                  setScheduleCount,
                                  disabled,
                                  onChanged,
                                  isAllProjects,
                              }) => {

    const [filters, setFilters] = useState({});
    const [filterLogic, setFilterLogic] = useState();
    const {isStudent} = usePermissions();

    const timeline = useSelector((state) => state.timeline.events);
    const isBlockEnabled = form.getFieldValue("IsScheduleEnabled");
    const isControlsEnabled = isBlockEnabled && !disabled;

    useEffect(() => {
        if (isBlockEnabled) {
            const afterProject = timeline.filter((item) => selectedProjects.includes(item.ProjectId));
            const filterHandlers = _.values(filters);
            if (filterLogic === ApiFilterIncludeType.Any) {
                const filterNames = _.keys(filters);
                const filterWithoutDateNames = _.without(filterNames, DatesFilterName);

                const afterDatesFilter = filterNames.includes(DatesFilterName) ? afterProject.filter(filters[DatesFilterName]) : afterProject;

                if (filterWithoutDateNames.length > 0) {
                    const afterFilter = filterWithoutDateNames.reduce((result, filterName) => {
                        return [...result, ...afterDatesFilter.filter(filters[filterName])];
                    }, []);
                    setScheduleCount(_.uniqBy(afterFilter, "Id").length);
                } else {
                    setScheduleCount(afterDatesFilter.length);
                }
            } else {
                const afterFilter = filterHandlers.reduce((result, handler) => {
                    return result.filter(handler);
                }, afterProject);
                setScheduleCount(afterFilter.length);
            }
        } else {
            setScheduleCount(null);
        }
    }, [filterLogic, selectedProjects, setScheduleCount, timeline, isBlockEnabled, filters]);

    function addFilterHandler(filterKey, filterFunc) {
        if (_.isFunction(filterFunc)) {
            setFilters((prev) => ({...prev, [filterKey]: filterFunc}));
        } else {
            if (filters[filterKey] == null) return;

            setFilters((prevFilters) => {
                const newFilters = {...prevFilters};
                delete newFilters[filterKey];
                return newFilters;
            });
        }
    }

    const onCopyUrl = (url) => {
        if (!url) return;

        navigator.clipboard.writeText(url);
        message.info("Copied");
    };

    const isIncludeCrew = form.getFieldValue(["Schedule", "TaskType"]) & ApiFilterTaskType.Booking;
    const isCrewOrLabourLineEnabled = isControlsEnabled && isIncludeCrew;

    const isOnlyConfirmedEnabled = isCrewOrLabourLineEnabled && form.getFieldValue(["Schedule", "FilterCrewType"]) === ApiFilterCrewType.CrewBookings;

    const isOptionalTeamRolesEnabled = isControlsEnabled && form.getFieldValue(["Schedule", "OptionalTeam"]) === true;

    const isYearLayout = form.getFieldValue("FilterLayout") === ApiFilterLayout.Year;
    const isWeekLayout = form.getFieldValue("FilterLayout") === ApiFilterLayout.Week;
    const scheduleGroupByItems = scheduleGroupBy.map((item) => ({
        ...item,
        disabled: (isYearLayout && item.value !== ApiFilterLayoutType.GroupedByProject) || (isWeekLayout && item.value !== ApiFilterLayoutType.GroupedByDay),
    }));

    const IgnoreFilteringLabel = () => (
        <>
            <Popover
                content="For any projects selected here, all events will be displayed regardless of the above filter settings."
                trigger="hover">
                <QuestionCircleOutlined className="form-item-label-icon"/>
            </Popover>
            Ignore Filtering
        </>
    );


    return (<Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
        <Form.Item wrapperCol={{offset: 8}} className="ant-form-item-without-validation">
            Show Events With...
        </Form.Item>
        <Form.Item label="Dates" name="Schedule" className="ant-form-item-without-validation">
            <Dates
                disabled={!isControlsEnabled}
                onChanged={onChanged}
                setFilterHandler={(handler) => addFilterHandler(DatesFilterName, handler)}></Dates>
        </Form.Item>
        <Form.Item label="Statuses" name={["Schedule", "StatusTags"]} className="ant-form-item-without-validation">
            <Statuses
                disabled={!isControlsEnabled}
                onChanged={onChanged}
                setFilterHandler={(handler) => addFilterHandler("Statuses", handler)}></Statuses>
        </Form.Item>
        <Form.Item
            label="Location"
            name={["Schedule"]}
            getValueProps={(schedule = {}) => {
                return {
                    value: {
                        ...schedule, teamMembers: schedule.Locations, contacts: schedule.ScheduleLocationContacts,
                    },
                };
            }}
            className="ant-form-item-without-validation">
            <Locations
                disabled={!isControlsEnabled}
                projectIds={selectedProjects}
                onChanged={onChanged}
                onChange={(value) => form.setFieldsValue({
                    Schedule: {
                        ...value, Locations: value.teamMembers, ScheduleLocationContacts: value.contacts,
                    },
                })}
                setFilterHandler={(handler) => addFilterHandler("Locations", handler)}></Locations>
        </Form.Item>

        <Form.Item label="Team Members" name={["Schedule"]} className="ant-form-item-without-validation">
            <Team
                onChanged={onChanged}
                disabled={!isControlsEnabled}
                projectIds={selectedProjects}
                setFilterHandler={(handler) => addFilterHandler("Contacts", handler)}></Team>
        </Form.Item>

        <Form.Item label="Departments" name={["Schedule", "DepartmentTags"]}
                   className="ant-form-item-without-validation">
            <Departments
                disabled={!isControlsEnabled}
                projectIds={selectedProjects}
                onChanged={onChanged}
                setFilterHandler={(handler) => addFilterHandler("Departments", handler)}></Departments>
        </Form.Item>
        <Form.Item label="Categories" name={["Schedule", "CategoryTags"]}
                   className="ant-form-item-without-validation">
            <Categories
                disabled={!isControlsEnabled}
                projectIds={selectedProjects}
                onChanged={onChanged}
                setFilterHandler={(handler) => addFilterHandler("Categories", handler)}></Categories>
        </Form.Item>

        <Form.Item label="Filter Logic" name={["Schedule", "FilterIncludeType"]}
                   className="ant-form-item-without-validation">
            <FilterLogic
                disabled={!isControlsEnabled}
                projectIds={selectedProjects}
                onChanged={onChanged}
                onItemsChanged={(val) => setFilterLogic(val)}></FilterLogic>
        </Form.Item>

        <Form.Item
            label={<IgnoreFilteringLabel/>}
            name={["Schedule"]}
            className="ant-form-item-without-validation">
            <ScheduleIgnoreProjects
                disabled={!isControlsEnabled}
                onChanged={onChanged}
                isAllProjects={isAllProjects}
            >
            </ScheduleIgnoreProjects>
        </Form.Item>

        <Row>
            <Col span={11}>
                <Form.Item
                    label="Include Crew"
                    labelCol={{span: 18}}
                    wrapperCol={{span: 2}}
                    name={["Schedule", "TaskType"]}
                    className="ant-form-item-without-validation">
                    <IncludeBookings disabled={!isControlsEnabled} onChanged={onChanged}></IncludeBookings>
                </Form.Item>
            </Col>
            <Col span={10}>
                <Form.Item name={["Schedule", "FilterCrewType"]} className="ant-form-item-without-validation">
                    <CrewOrLabourLine
                        disabled={!isCrewOrLabourLineEnabled}
                        onChanged={onChanged}
                        onItemsChanged={() => {
                        }}></CrewOrLabourLine>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col span={10} offset={8}>
                <Form.Item
                    valuePropName="checked"
                    labelAlign="left"
                    labelCol={{span: 18}}
                    wrapperCol={{span: 2}}
                    label="Only Confirmed Crew"
                    name={["Schedule", "IsOnlyConfirmedBooking"]}
                    className="ant-form-item-without-validation">
                    <Checkbox disabled={!isOnlyConfirmedEnabled} onChange={onChanged}></Checkbox>
                </Form.Item>
            </Col>
        </Row>

        <Divider orientation="left">Appearance</Divider>
        <Form.Item label="Default Layout" name={["FilterLayout"]} className="ant-form-item-without-validation">
            <LayoutSingleSelector
                onChanged={onChanged}
                disabled={!isControlsEnabled}
                setFilterHandler={() => {
                }}></LayoutSingleSelector>
        </Form.Item>
        <Form.Item label="Group By" name={["FilterLayoutType"]} className="ant-form-item-without-validation">
            <GroupBySingleSelector
                onChanged={onChanged}
                disabled={!isControlsEnabled}
                items={scheduleGroupByItems}
                setFilterHandler={() => {
                }}></GroupBySingleSelector>
        </Form.Item>
        <Row>
            <Col span={14}>
                <Form.Item
                    disabled={!isControlsEnabled}
                    label="Columns"
                    name="Schedule"
                    className="ant-form-item-without-validation"
                    labelCol={{span: 14}}
                    wrapperCol={{span: 14}}>
                    <Columns
                        disabled={!isControlsEnabled}
                        setFilterHandler={() => {
                        }}
                        onChanged={onChanged}
                        items={[columns.Location, columns.Team, columns.Notes, columns.Categories, columns.Departments, columns.Reports, columns.Status,]}
                        selected={[columns.Location, columns.Team, columns.Notes, columns.Categories, columns.Departments, columns.Reports, columns.Status,]}></Columns>
                </Form.Item>
            </Col>
            <Col span={6} offset={4}>
                <Form.Item
                    valuePropName="checked"
                    labelAlign="right"
                    label=""
                    disabled={!isOptionalTeamRolesEnabled}
                    labelCol={{span: 0}}
                    wrapperCol={{span: 24}}
                    name={["Schedule", "OptionalTeamRoles"]}
                    className="ant-form-item-without-validation">
                    <Checkbox disabled={!isOptionalTeamRolesEnabled} onChange={onChanged}>
                        Show Roles
                    </Checkbox>
                </Form.Item>
            </Col>
        </Row>
        <Form.Item label="Time Zone" name="TimeZoneId" className="ant-form-item-without-validation">
            <TimeZone onChanged={onChanged} disabled={!isControlsEnabled} setFilterHandler={() => {
            }}></TimeZone>
        </Form.Item>
        <Divider orientation="left">Calendar Subscription</Divider>
        <Form.Item
            label="Subscription Enabled"
            name="IsFeedEnabled"
            valuePropName="checked"
            className="ant-form-item-without-validation">
            {isStudent ? (<Tooltip title="Users with student access may not share calendar subscription links.">
                <Switch disabled={true}/>
            </Tooltip>) : (<Switch disabled={isStudent || !isControlsEnabled} onChange={onChanged}/>)}
        </Form.Item>
        <Form.Item label="Subscription Url" className="ant-form-item-without-validation">
            {isStudent || !form.getFieldValue("IsFeedEnabled") ? (<span className="url disabled">&mdash;</span>) : (
                <a href={form.getFieldValue("SubscriptionUrl")} target="blank">
                    <span className="url">{form.getFieldValue("SubscriptionUrl")}</span>
                </a>)}
            <span className="url-copy" onClick={() => onCopyUrl(form.getFieldValue("SubscriptionUrl"))}>
                    <CopyOutlined/>
                </span>
        </Form.Item>
    </Form>);
};