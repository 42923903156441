import {createSlice} from "@reduxjs/toolkit";

const durationShortcutsSlice = createSlice({
    name: "durationShortcuts",
    initialState: {
        shortcuts: []
    },
    reducers: {
        setDurationShortcuts(state, action) {
            state.shortcuts = action.payload;
        }
    },
});

export const {setDurationShortcuts} = durationShortcutsSlice.actions;

export default durationShortcutsSlice.reducer;