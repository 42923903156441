import {createSlice} from '@reduxjs/toolkit';
import {ViewMode} from "../../constants";

const initialState = {
    events: [],
    bookings: [],
    selectedTimeZone: null,
    isLoading: false,
    filterDateRange: [],
    importingData: null,
    currentView: ViewMode.List,
};

const timelineSlice = createSlice({
    name: 'timeline',
    initialState,
    reducers: {
        setEvents(state, action) {
            state.events = action.payload;
        },
        setBookings(state, action) {
            state.bookings = action.payload;
        },
        setSelectedTimeZone(state, action) {
            state.selectedTimeZone = action.payload;
        },
        setTimeLineLoading(state, action) {
            state.isLoading = action.payload;
        },
        setFilterDateRange(state, action) {
            state.filterDateRange = action.payload;
        },
        setImportingData(state, action) {
            state.importingData = action.payload;
        },
        setCurrentView: (state, action) => {
            state.currentView = action.payload;
        },
    },
});

export const {
    setEvents,
    setBookings,
    setSelectedTimeZone,
    setTimeLineLoading,
    setFilterDateRange,
    setImportingData,
    setCurrentView
} = timelineSlice.actions;

export default timelineSlice.reducer;