import React, {useState, useEffect} from "react";
import moment from "moment";
import {useSelector, useDispatch} from "react-redux";
import DateRangeSelector from "../../../Common/Tables/FilterBar/DateRangeSelector";
import {setDateRange} from "../../../../redux/reducers/dateRange";
import {getRequirementDateRangeWithDefaults} from "../../../../services/requirements";

const formatToISO = (date) => moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');

const ItemsDateSelector = ({setFilterHandler}) => {
    const [selectedItems, setSelectedItems] = useState();
    const dispatch = useDispatch();
    const {startDate, endDate} = useSelector((state) => state.dateRange);

    useEffect(() => {
        const dates = [moment(startDate), moment(endDate)];
        setSelectedItems(dates);
        applyFilter(dates);
    }, [startDate, endDate]);

    const setDateRangeAndDispatch = (startDate, endDate) => {
        startDate = formatToISO(moment(startDate).startOf('day'));
        endDate = formatToISO(moment(endDate).endOf('day'));
        dispatch(setDateRange({startDate, endDate}));
    };

    function applyFilter(filterItems) {
        if (filterItems.length === 0) {
            setFilterHandler(() => true);
        } else {
            const [filterStart, filterEnd] = filterItems;
            setFilterHandler((item) => {
                if (item.Requirement.StartDateTime === null) return true;

                const itemStartDay = moment(item.Requirement.StartDateTime).startOf("D");
                const itemEndDay = moment(item.Requirement.EndDateTime).startOf("D");
                const filterStartDay = moment(filterStart).startOf("D");
                const filterEndDay = moment(filterEnd).startOf("D");

                if (itemStartDay < filterStartDay && itemEndDay < filterStartDay) return false;

                return itemStartDay <= filterEndDay;
            });
        }
    }

    return (
        <DateRangeSelector
            selectedItems={selectedItems}
            allItemsText="Select Dates"
            search
            onDateRangeChange={setDateRangeAndDispatch}
            fetchAllDatesRange={getRequirementDateRangeWithDefaults}
        />
    );
};

export default ItemsDateSelector;