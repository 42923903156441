import React from "react";
import {message} from "antd";
import {cloneProductionBooks, loadProductionBooks} from "../../../services/productionBooks";
import {errorFromHttpResponse} from "../../../helpers/error";
import {useDispatch, useSelector} from "react-redux";
import {setSelected} from "../../../redux/reducers/mainTable";

const getMessage = (selected) =>
    selected.length === 1
        ? `${selected[0].Name} was successfully cloned`
        : `${selected.length} Production Books were successfully cloned`;

const CloneProductionBook = ({disabled, selected = []}) => {
    const dispatch = useDispatch();
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);

    const onClone = () => {
        if (disabled) return;

        cloneProductionBooks(selected.map((i) => i.Id))
            .then((newIds) => {
                return loadProductionBooks(activePortfolioId).then((response) => ({newIds, response}));
            })
            .then(({newIds, response}) => {              
                dispatch(setSelected([]));
                const clonedItems = response.Filters.Filters.filter((item) => newIds.some((newId) => newId.Id === item.Id));
                dispatch(setSelected(clonedItems));
                message.success(getMessage(selected));
            })
            .catch((err) => {
                const errorText = errorFromHttpResponse(err);
                message.error(errorText);
            });
    };

    return (
        <div disabled={disabled} onClick={onClone}>
            Clone
        </div>
    );
};

export default CloneProductionBook;
