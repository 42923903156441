import React, {useState, useEffect} from "react";
import {DatePicker} from "antd";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";

const {RangePicker} = DatePicker;

const TimelineDates = ({placeholder, onDateChanged, isDisabled, startDate, endDate, showTimeShiftInfo}) => {
    const [isOpen, setIsOpen] = useState();
    const [value, setValue] = useState([]);
    const {date: dateFormat} = useDateTimeFormat();
    useEffect(() => {
        setValue([startDate, endDate]);
    }, [startDate, endDate]);

    const onOpenChange = (open) => {
        if (isOpen !== open) setIsOpen(open);
    };

    const onChange = (val) => {
        setValue(val);
        if (onDateChanged) onDateChanged(val);
    };

    const onCalendarChange = (val) => setValue(val);

    const rangePickerFooter = () => {
        return (
            <>
                {showTimeShiftInfo &&
                    <div className="center">The time-frame of all associated bookings will be updated as well.</div>}
            </>
        );
    };

    return (
        <RangePicker
            open={isOpen}
            style={{width: "100%"}}
            format={dateFormat}
            disabled={isDisabled}
            value={value}
            placeholder={placeholder}
            onCalendarChange={onCalendarChange}
            onChange={onChange}
            onOpenChange={onOpenChange}
            renderExtraFooter={rangePickerFooter}
        />
    );
};

export default TimelineDates;