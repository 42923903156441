import {EditOutlined} from "@ant-design/icons";
import {Space, Table} from "antd";
import React, {useRef} from "react";
import {useState} from "react";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {TagType} from "../../../constants/constants";
import {setSelected} from "../../../redux/reducers/mainTable";
import {setTagToEdit} from "../../../redux/reducers/tags";
import {loadTags} from "../../../services/tags";
import {TagIcon} from "../../../helpers/Tags";
import EditTag from "./EditTag/EditTag";
import TagsFilterBar from "./FilterBar/TagsFilterBar";
import useWindowSize from "../../../hooks/useWindowSize";
import {useVT} from "virtualizedtableforantd4";

const column = {
    title: "Status",
    dataIndex: "Name",
    key: (i, record) => record.AccountTagId,
    width: "auto",
};

const iconColumn = {
    title: "Icon",
    dataIndex: "Icon",
    key: (i, record) => record.AccountTagId,
    render: (icon) => <TagIcon icon={icon}/>,
    width: "auto",
};

const columnNames = {
    [TagType.Category]: "Category",
    [TagType.Department]: "Department",
    [TagType.Group]: "Group",
    [TagType.Role]: "Role",
    [TagType.TaskStatus]: "Status",
};

const Tags = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const selectedTagType = useSelector((state) => state.settings.selectedTagType);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const size = useWindowSize();
    const tableRef = useRef();
    const tags = {
        [TagType.Category]: useSelector((state) => state.tags.categories),
        [TagType.Department]: useSelector((state) => state.tags.departments),
        [TagType.Group]: useSelector((state) => state.tags.groups),
        [TagType.Role]: useSelector((state) => state.tags.roles),
        [TagType.TaskStatus]: useSelector((state) => state.tags.statuses),
    };

    const actionsColumn = {
        key: "action",
        width: "30px",
        className: "actions-column",
        alwaysVisible: true,
        render: (record) => (
            <EditOutlined
                onClick={(e) => {
                    loadItemToEdit(record);
                    e.stopPropagation();
                }}
                className="btn-table-actions"
            />
        ),
    };

    const columns =
        selectedTagType === TagType.TaskStatus
            ? [
                {
                    ...column,
                    width: "200px",
                },
                iconColumn,
                actionsColumn,
            ]
            : [
                {
                    ...column,
                    title: columnNames[selectedTagType],
                },
                actionsColumn,
            ];

    useEffect(() => {
        if (activePortfolio) {
            loadTags().finally(() => setLoading(false));
        }
    }, [activePortfolio]);

    const items = tags[selectedTagType];
    const [filteredData, setFilteredData] = useState([]);

    const applyFilters = (filtersData) => {
        setFilteredData(filtersData);
    };

    const setSelectedRowKeys = (selected) => {
        const selectedItems = items.filter((i) => selected.includes(i.AccountTagId));
        dispatch(setSelected(selectedItems));
    };

    const loadItemToEdit = (item) => {
        dispatch(setTagToEdit(item));
    };

    const tableHeight = (size?.height || 800) - (tableRef.current?.offsetTop || 0) - 40;
    const [vt] = useVT(() => ({scroll: {y: tableHeight}}), [tableHeight, items]);

    return (
        <>
            <Space>
                {" "}
                <TagsFilterBar items={items} applyFilters={applyFilters}/>
            </Space>

            <Table
                ref={tableRef}
                loading={loading}
                columns={columns}
                dataSource={filteredData}
                pagination={false}
                rowKey={(record) => record.AccountTagId}
                className="common-table"
                size="small"
                scroll={{y: tableHeight}}
                components={vt}
                rowSelection={{
                    hideSelectAll: true,
                    type: "radio",
                    onChange: (selected) => setSelectedRowKeys(selected),
                }}
                onRow={(record) => ({
                    onClick: () => loadItemToEdit(record),
                })}
            />
            <EditTag tagType={selectedTagType}></EditTag>
        </>
    );
};

export default Tags;
