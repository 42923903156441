import {createSlice} from "@reduxjs/toolkit";
import moment from "moment/moment";

const dateRangeSlice = createSlice({
    name: "dateRange",
    initialState: {
        startDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
        endDate: moment().add(1, 'year').startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
    },
    reducers: {
        setDateRange(state, action) {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
    },
});

export const {setDateRange} = dateRangeSlice.actions;

export default dateRangeSlice.reducer;