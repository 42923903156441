import {createSlice} from "@reduxjs/toolkit";
import {TagType} from "../../constants";

const tagsSlice = createSlice({
    name: "tags",
    initialState: {
        roles: [],
        departments: [],
        categories: [],
        groups: [],
        projectRoles: {},
        projectGroups: {},
        projectCategories: {},
        projectDepartments: {},
        projectGroupsExtended: {},
        tagToEdit: null
    },
    reducers: {


        batchMergeTags(state, action) {
            const items = action.payload;

            items.forEach(({Type, ProjectTags, AllItems}) => {
                const projectTagType = {
                    [TagType.Role]: "projectRoles",
                    [TagType.Group]: "projectGroups",
                    [TagType.Category]: "projectCategories",
                    [TagType.Department]: "projectDepartments",
                }

                const tagType = {
                    [TagType.Role]: "roles",
                    [TagType.Group]: "groups",
                    [TagType.Category]: "categories",
                    [TagType.Department]: "departments",
                    [TagType.TaskStatus]: "statuses",
                }

                if (Type !== TagType.TaskStatus) {
                    ProjectTags.forEach(pt => state[projectTagType[Type]][pt.ProjectId] = pt.Tags)
                }
                state[tagType[Type]] = AllItems;
            });

        },

        setProjectGroupsExtended(state, action) {
            action.payload.forEach(pt =>
                state.projectGroupsExtended[pt.Project.Id] = pt.Tags)
        },

        clearTags(state) {
            state.roles = [];
            state.departments = [];
            state.categories = [];
            state.groups = [];
            state.projectRoles = {};
            state.projectGroups = {};
            state.projectGroupsExtended = {}
        },

        setTagToEdit(state, action) {
            state.tagToEdit = action.payload;
        },
    },
});

export const {clearTags, batchMergeTags, setProjectGroupsExtended, setTagToEdit} = tagsSlice.actions;

export default tagsSlice.reducer;
