import React, {useEffect, useState} from "react";
import _ from "lodash";
import {Checkbox, TreeSelect} from "antd";

import "./TreeSelectFilter.scss";

export const isOptionSelected = (item, items) => (items || []).includes(item);

const TreeSelectFilter = ({
                              defaultSelected = [],
                              onChanged,
                              selectDefaultOption,
                              placeholder,
                              treeNodeLabelProp,
                              showCheckedStrategy = TreeSelect.SHOW_CHILD,
                              treeData = [],
                              disabled,
                              value = [],
                              forwardRef,
                              treeDefaultExpandedKeys,
                              hideDivider = false,
                          }) => {
    const [isDefaultChecked, setDefaultChecked] = useState(true);
    const [selected, setSelected] = useState(defaultSelected);

    useEffect(() => {
        if (value && value.length > 0) {
            if (!_.isEqual(selected, value)) {
                setSelected(value);
            }
        } else {
            if (selected.length > 0) {
                setSelected([]);
            }
        }
    }, [value, selected]);

    useEffect(() => {
        const isDefaultSelection = _.xor(selected, defaultSelected).length === 0;
        setDefaultChecked(isDefaultSelection);
    }, [selected]);

    function changeSelected(selected) {
        setSelected(selected);
        if (onChanged) {
            onChanged(selected);
        }
    }

    return (
        <div>
            <TreeSelect
                showSearch={true}
                treeDefaultExpandedKeys={treeDefaultExpandedKeys}
                ref={forwardRef}
                treeData={treeData}
                value={selected}
                disabled={disabled}
                treeCheckable={true}
                className="details-panel-tree-select"
                dropdownClassName="details-panel-tree-menu"
                showCheckedStrategy={showCheckedStrategy}
                placeholder={placeholder}
                onChange={changeSelected}
                treeNodeLabelProp={treeNodeLabelProp}
                dropdownRender={(menu) => (
                    <>
                        {selectDefaultOption && (
                            <div className="tree-disable-all-option">
                                <Checkbox
                                    key={selectDefaultOption.value}
                                    checked={isDefaultChecked}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            changeSelected(defaultSelected);
                                        }
                                        setDefaultChecked(e.target.checked);
                                    }}>
                                    {selectDefaultOption.title}
                                </Checkbox>
                            </div>
                        )}
                        {!hideDivider && <div className="ant-menu-item-divider"></div>}
                        {menu}
                    </>
                )}
                filterOption={(input, option) =>
                    ((_.isString(option.title) ? option.title : option.title?.props?.children[0]) || "")
                        .toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                }></TreeSelect>
        </div>
    );
};

export default TreeSelectFilter;