import React, {useState, useEffect, useRef} from "react";
import {Collapse, Form, message} from "antd";
import {FolderOpenFilled, InfoCircleOutlined, ExpandOutlined} from "@ant-design/icons";
import {NewRecordState, ContactType} from "../../../constants/constants";
import {createContact, loadContacts, getContact, updateContact} from "../../../services/contacts";
import {continueEdit} from "../../../redux/reducers/detailsPanel";
import {mapTagToOption} from "../../../helpers/Tags";
import {isRequestCanceled, errorFromHttpResponse} from "../../../helpers/error";
import Spaces from "./Spaces";
import {useSelector, useDispatch} from "react-redux";
import ImageUpload, {changeImageUpdatePayload} from "../../Common/ImageUpload/ImageUpload";
import ProjectHistory from "./ProjectHistory/ProjectHistory";
import PersonInputs from "./PersonInputs";
import OrganizationInputs from "./OrganizationInputs";
import LocationInputs from "./LocationInputs";
import {loadPortfolios} from "../../../services/projects";
import AuditFormItem from "../../Common/AuditFormItem";

const InformationHeader = (<>
    <InfoCircleOutlined/> Information
</>);
const ProjectHistoryHeader = (<>
    <FolderOpenFilled/> Project History
</>);

const SpacesHeader = (<>
    <ExpandOutlined/> Spaces
</>);

const prepareFormData = (contact) => {
    const formData = {
        ...contact,
        Specialties: contact.Specialties.map((t) => t.AccountTagId.toString()),
        Groups: contact.Groups.map((t) => t.AccountTagId.toString()),
    };
    if (!formData.Websites || formData.Websites.length === 0) {
        formData.Websites = [{}];
    }

    if (!formData.Addresses || formData.Addresses.length === 0) {
        formData.Addresses = [{}];
    }
    return formData;
};

const ContactDetailsPanel = ({apiContactType, contactType}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const firstInputRef = useRef();
    const nextInputRef = useRef();
    const [currentUserState, setCurrentUserState] = useState(NewRecordState.Empty);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const roles = useSelector((state) => state.tags.roles).map(mapTagToOption);
    const groups = useSelector((state) => state.tags.groups).map(mapTagToOption);
    const contactToEdit = useSelector((state) => state.detailsPanel.item);

    if (currentUserState === NewRecordState.RequiredFieldsSubmitted) {
        setTimeout(() => nextInputRef?.current?.focus(), 100);
    }

    useEffect(() => {
        if (!contactToEdit) {
            setTimeout(() => firstInputRef.current.focus(), 200);
            return;
        }

        setCurrentUserState(NewRecordState.ReadyForEdit);

        const formData = prepareFormData(contactToEdit);
        form.setFieldsValue(formData);
    }, [contactToEdit, form]);

    function onFinish(finishedForm) {
        if (currentUserState === NewRecordState.Empty) {
            prepareAndCreateContact(finishedForm);
        }

        if (currentUserState === NewRecordState.ReadyForEdit) {
            prepareAndUpdateContact(finishedForm);
        }
    }

    function prepareAndCreateContact(finishedForm) {
        createContact(activePortfolio.Id, apiContactType, {
            Company: finishedForm.Company, ContactType: apiContactType, FirstName: finishedForm.FirstName,
        })
            .then((createContactResponse) => {
                message.success(`${createContactResponse.Company} was created`);
                dispatch(continueEdit({item: createContactResponse}));
                return loadContacts(activePortfolio.Id);
            })
            .catch((err) => {
                console.error(err);
                setCurrentUserState(NewRecordState.Empty);
            });

        setCurrentUserState(NewRecordState.RequiredFieldsSubmitted);
    }

    function prepareAndUpdateContact(finishedForm) {
        const updatePayload = {
            ...contactToEdit, ...finishedForm,
            Specialties: finishedForm.Specialties.map((t) => parseInt(t, 10)),
            Groups: finishedForm.Groups.map((t) => parseInt(t, 10)), ...changeImageUpdatePayload(finishedForm, contactToEdit),
            Websites: finishedForm.Websites.filter((w) => w.Url),
            Addresses: finishedForm.Addresses.filter((a) => a.Line1),
            Id: contactToEdit.Id,
            ContactType: apiContactType,
            ChildContacts: finishedForm.ChildContacts || [],
        };
        delete updatePayload.Type;

        updateContact(updatePayload)
            .then(() => getContact(contactToEdit.Id).then((contact) => {
                dispatch(continueEdit({item: contact}));
                if (contactToEdit.IsAccountProfile) loadPortfolios();
            }))
            .then(() => loadContacts(activePortfolio.Id))
            .catch((err) => {
                if (!isRequestCanceled(err)) {
                    const errorText = errorFromHttpResponse(err);
                    console.error(err);
                    message.error(errorText);
                }
            });
    }

    const isOptionalFieldDisabled = currentUserState !== NewRecordState.ReadyForEdit;

    const contactLabels = {
        [ContactType.Person]: "first name",
        [ContactType.Organization]: "name",
        [ContactType.Location]: "name"
    };

    const articles = {
        [ContactType.Person]: "a",
        [ContactType.Organization]: "an",
        [ContactType.Location]: "a"
    };

    const getPlaceholderText = (contactType, currentUserState) => {
        const label = contactLabels[contactType];
        const article = articles[contactType];
        return currentUserState === NewRecordState.Empty ? `Enter ${label} to create ${article} ${contactType.toLowerCase()}` : `Enter ${label}`;
    };

    const placeholderForFirstName = getPlaceholderText(contactType, currentUserState);

    const save = async () => {
        try {
            const values = await form.validateFields();
            onFinish({...values, ChildContacts: form.getFieldValue("ChildContacts")});
        } catch (err) {
            console.error(err);
        }
    };

    const onSpaceChanged = () => {
        loadContacts(activePortfolio.Id);
    };

    return (<div className="side-panel with-collapse">
        <Collapse defaultActiveKey={["Information", "ProjectHistory", "Spaces"]}>
            <Collapse.Panel key="Information" header={InformationHeader}>
                <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                    {contactType === ContactType.Person && (<PersonInputs
                        save={save}
                        firstInputRef={firstInputRef}
                        nextInputRef={nextInputRef}
                        isOptionalFieldDisabled={isOptionalFieldDisabled}
                        placeholderForFirstName={placeholderForFirstName}
                        roles={roles}
                        groups={groups}
                        contactToEdit={contactToEdit}
                        form={form}
                        showOrganizationField={true}
                    />)}
                    {contactType === ContactType.Organization && (<OrganizationInputs
                        save={save}
                        firstInputRef={firstInputRef}
                        nextInputRef={nextInputRef}
                        isOptionalFieldDisabled={isOptionalFieldDisabled}
                        placeholderForFirstName={placeholderForFirstName}
                        roles={roles}
                        groups={groups}
                        contactToEdit={contactToEdit}
                        form={form}
                    />)}
                    {contactType === ContactType.Location && (<LocationInputs
                        save={save}
                        firstInputRef={firstInputRef}
                        nextInputRef={nextInputRef}
                        isOptionalFieldDisabled={isOptionalFieldDisabled}
                        placeholderForFirstName={placeholderForFirstName}
                        roles={roles}
                        groups={groups}
                        contactToEdit={contactToEdit}
                        form={form}
                    />)}

                    <Form.Item label="Image" name="ImageUrl" className="ant-form-item-without-validation">
                        <ImageUpload onChanged={save}/>
                    </Form.Item>

                    <AuditFormItem item={contactToEdit} label="Created" timeProp="CreatedAt" userProp="CreatedBy"/>

                    <AuditFormItem item={contactToEdit} label="Last Updated" timeProp="UpdatedAt"
                                   userProp="UpdatedBy"/>
                </Form>
            </Collapse.Panel>
            {contactType === ContactType.Location && (
                <Collapse.Panel key="Spaces" header={SpacesHeader} className="spaces-panel">
                    <Form form={form} layout="horizontal" size="middle">
                        <Form.Item name="ChildContacts">
                            <Spaces
                                isReadOnly={isOptionalFieldDisabled}
                                activePortfolioId={activePortfolio?.Id}
                                parentContactId={contactToEdit?.Id}
                                onChanged={onSpaceChanged}
                            />
                        </Form.Item>
                    </Form>
                </Collapse.Panel>)}
            <Collapse.Panel key="ProjectHistory" header={ProjectHistoryHeader}
                            className="contact-details-panel-projects">
                <Form form={form} layout="horizontal">
                    <Form.Item name="ProjectEntries">
                        <ProjectHistory></ProjectHistory>
                    </Form.Item>
                </Form>
            </Collapse.Panel>
        </Collapse>
    </div>);
};

export default ContactDetailsPanel;
