import React, {useEffect, useRef, useState} from "react";
import {Form, Modal} from "antd";
import {GroupOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {createProjectColorTagRendered, mapProjectWithBadgeToTag} from "../../../helpers/Tags";
import {InputWithFormItem, SelectWithFormItem, TextAreaWithFormItem} from "../../Common/Input";

import _ from "lodash";
import moment from "moment";

import {getCollection, loadCollections} from "../../../services/resources";
import {addCollection, refreshRequirements} from "../../../services/requirements";

import {ApiProjectType} from "../../../constants/constants";
import ContactSelect from "../../Common/Selects/ContactSelect";
import TimelineDates from "../../Timeline/DetailsPanel/TimelineDates";

const AddCollectionModal = ({visible, onClose}) => {
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [collection, setCollection] = useState();
    const [dates, setDates] = useState([]);

    const form = useRef();
    const focusInputRef = useRef();

    const projects = useSelector((state) => state.projects.projects).filter((p) => p.State !== ApiProjectType.Archive);
    const sortedProjects = _.sortBy(projects, (p) => p.Name);

    const collections = useSelector((state) => state.resources.collections);
    const sortedCollections = _.sortBy(collections, (p) => p.Name);

    const selectedProjects = useSelector((state) => state.projects.activeProjects);
    const singleProject = selectedProjects.length === 1 ? selectedProjects[0] : null;

    useEffect(() => {
        if (!activePortfolioId) return;

        if (!dataLoaded) {
            loadCollections(activePortfolioId).then(() => setDataLoaded(true));
        }
    }, [activePortfolioId, dataLoaded, collections, selectedProjects]);

    const onOk = async () => {
        const values = await form.current.validateFields();
        const [start, end] = values.Dates || [];
        const payload = {
            AccountId: activePortfolioId,
            ProjectId: parseInt(values.ProjectIds[0], 10),
            CollectionId: values.CollectionId,
            Name: values.Name,

            StartDateTime: start?.format("yyyy-MM-DDThh:mm"),
            EndDateTime: end?.format("yyyy-MM-DDThh:mm"),
            LocationId: values.LocationId,
            Description: values.Description,
        };

        setConfirmLoading(true);

        addCollection(payload).then((response) => {
            refreshRequirements().then(() => {
                setConfirmLoading(false);
                onCancel();
            });
        });
    };

    const onCancel = () => {
        form.current.resetFields();
        setCollection();
        setDates();

        onClose();
    };

    const onChangeDates = (range) => {
        form.current.setFieldsValue({Dates: range});
        setDates(range?.map((d) => d.format()));
    };

    const autoFillDates = (projectIds = []) => {
        const {Dates: formDates = []} = form.current.getFieldsValue(["Dates"]);
        if (formDates?.length === 2) return;

        const project = projects.find((p) => p.Id.toString() === projectIds[0]);

        if (!project) return;

        const dates = [project.ProjectDate, project.ProjectEndDate].filter((d) => d);

        setDates(dates);
        form.current.setFieldsValue({Dates: dates.map((d) => moment(d))});
    };

    const onCollectionChanged = (id) => {
        if (id) {
            getCollection(id).then((c) => setCollection(c));
        } else {
            setCollection(null);
        }
    };

    const [start, end] = dates || [];

    return (
        <>
            <Modal
                title={
                    <>
                        <GroupOutlined/> &nbsp; Add a Collection
                    </>
                }
                visible={visible}
                maskClosable={false}
                confirmLoading={confirmLoading}
                onOk={onOk}
                onCancel={onCancel}
                onClose={onClose}>
                <Form
                    ref={form}
                    name="add-collection-req"
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    layout="horizontal"
                    size="middle"
                    initialValues={{ProjectIds: singleProject ? [singleProject?.Id.toString()] : []}}>
                    <SelectWithFormItem
                        label={"Project"}
                        name={["ProjectIds"]}
                        mode="tags"
                        forwardedRef={singleProject ? null : focusInputRef}
                        showSearch={true}
                        rules={[{required: true, message: "Select a project"}]}
                        tagRender={createProjectColorTagRendered(sortedProjects, false)}
                        options={sortedProjects.map((p) => mapProjectWithBadgeToTag(p))}
                        style={{width: "100%"}}
                        placeholder="Select a project"
                        closeOnChange={true}
                        onChanged={autoFillDates}></SelectWithFormItem>

                    <SelectWithFormItem
                        label={"Collection"}
                        name={["CollectionId"]}
                        mode="single"
                        rules={[{required: true, message: "Select a collection"}]}
                        showSearch
                        options={sortedCollections.map((m) => ({value: m.Id, label: m.Name}))}
                        style={{width: "100%"}}
                        placeholder="Select a collection"
                        onChanged={onCollectionChanged}></SelectWithFormItem>

                    <InputWithFormItem
                        name={["Name"]}
                        label={"Requirement"}
                        hasFeedback
                        rules={[{required: true, message: "Requirement"}]}
                        placeholder="Enter a requirement name"
                    />

                    <Form.Item label={"Dates"} name={["Dates"]} className="ant-form-item-without-validation">
                        <TimelineDates
                            onDateChanged={onChangeDates}
                            startDate={start && moment(start)}
                            endDate={end && moment(end)}
                            showTimeShiftInfo={false}
                        />
                    </Form.Item>

                    <Form.Item name={["LocationId"]} label={"Location"} className="ant-form-item-without-validation">
                        <ContactSelect
                            onChange={(v) => {
                            }}
                            onChanged={() => {
                            }}
                            disabledContactTypes={[0, 1]}
                            groupSpaces={true}
                            onOpenCreatePopup={() => {
                            }}
                            hideQuickCreate={true}
                        />
                    </Form.Item>

                    <TextAreaWithFormItem name={["Description"]} label={"Details"} autoSize={{minRows: 2}}
                                          placeholder="Enter details"/>

                    <Form.Item name="msg" colon={false} label={<span></span>}
                               className="ant-form-item-without-validation">
                        <div hidden={!collection}>
                            {collection?.Items.length} new requirement{collection?.Items.length > 1 ? "s" : ""} will be
                            created.
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AddCollectionModal;
