import React, {useEffect, useState} from "react";
import {Form} from "antd";
import moment from "moment";
import {useSelector} from "react-redux";
import {InputWithFormItem, SelectWithFormItem, TextAreaWithFormItem} from "../../../Common/Input";
import {createProjectColorTagRendered, geTagsGroup, mapProjectWithBadgeToTag, TagIcon} from "../../../../helpers/Tags";
import {ApiProjectType, TagType} from "../../../../constants";
import AuditFormItem from "../../../Common/AuditFormItem";
import ContactSelect from "../../../Common/Selects/ContactSelect";
import TimelineDates from "../../../Timeline/DetailsPanel/TimelineDates";
import TagsSelect from "../../../Common/Selects/TagsSelect";

const InformationBlock = ({
                              form,
                              itemToEdit,
                              save,
                              isOptionalFieldDisabled,
                              focusedInputRef,
                              isSingleProject
                          }) => {

    const departments = useSelector((state) => state.tags.departments || []);
    const projects = useSelector((state) => state.projects.projects).filter((p) => p.State !== ApiProjectType.Archive);
    const [dates, setDates] = useState([]);

    const detectDates = () => {

        const {Dates = []} = form.getFieldsValue(["Dates"]);

        const start = itemToEdit?.StartDateTime || (Dates?.length === 2 && Dates[0]?.format());
        const end = itemToEdit?.EndDateTime || (Dates?.length === 2 && Dates[1]?.format());

        return [start, end].filter(Boolean);
    };

    const onChangeDepartment = () => {
        const list = form.getFieldValue("DepartmentTagId");
        const tag = list.pop();
        form.setFieldsValue({DepartmentTagId: tag ? parseInt(tag, 10) : null});
        save();
    };

    const onChangeProject = (list) => {
        const id = list.pop();

        const {Dates: formDates = []} = form.getFieldsValue();
        const projectIds = id ? [id] : [];
        const project = id && projects.find((p) => p.Id.toString() === id);
        const hasStateDates = dates.start && dates.end;
        const hasFormDates = formDates.length === 2;
        const hasProjectDates = project && project.ProjectDate && project.ProjectEndDate;

        let datesRange = [];
        if (!hasStateDates && !hasFormDates && hasProjectDates) {
            datesRange = [project.ProjectDate, project.ProjectEndDate];
        } else if (hasStateDates) {
            datesRange = dates;
        } else if (hasFormDates) {
            datesRange = [formDates[0]?.format(), formDates[1]?.format()];
        }

        form.setFieldsValue({
            ProjectIds: projectIds, Dates: datesRange.map((d) => moment(d)),
        });

        setDates(datesRange);

        save();
    };

    const onChangeDates = (range = []) => {
        form.setFieldsValue({Dates: range});

        setDates(range?.map((d) => d.format()) || []);

        save();
    };

    const autoFillDatesFromSelectedProject = () => {

        const formDates = form.getFieldValue("Dates") || [];
        if (formDates?.length === 2) {
            return;
        }

        const project = projects.find(p => p.Id.toString() === form.getFieldValue("ProjectIds")?.[0]);
        if (project?.ProjectDate && project?.ProjectEndDate) {

            const datesRange = [project.ProjectDate, project.ProjectEndDate].filter(Boolean);

            form.setFieldsValue({
                Dates: datesRange.map((d) => moment(d)),
            });

            setDates(datesRange);
        }
    }

    const statuses = useSelector((state) => state.tags.statuses || []);
    const statusOptions = statuses.map((status) => ({
        value: status.AccountTagId, title: status.Name, label: (<>
            <TagIcon icon={status.Icon}/> {status.Name}
        </>),
    }));

    const [start, end] = dates;
    const canEditDates = !isOptionalFieldDisabled || dates.length === 2;

    useEffect(() => {
        setDates(detectDates());
    }, [form, itemToEdit, dates?.join("")]);

    useEffect(() => {
        if (itemToEdit) {
            return;
        }

        autoFillDatesFromSelectedProject();
    }, [itemToEdit]);

    return (<>
        <SelectWithFormItem
            form={form}
            label={"Project"}
            name={["ProjectIds"]}
            mode="multiple"
            forwardedRef={isSingleProject ? null : focusedInputRef}
            showSearch={true}
            rules={[{required: true, message: "Select a project"}]}
            tagRender={createProjectColorTagRendered(projects, false)}
            options={projects.map((p) => mapProjectWithBadgeToTag(p))}
            style={{width: "100%"}}
            placeholder="Select a project"
            closeOnChange={true}
            onChanged={onChangeProject}></SelectWithFormItem>

        <InputWithFormItem
            form={form}
            name={["Name"]}
            label={"Requirement"}
            forwardedRef={isSingleProject ? focusedInputRef : null}
            hasFeedback
            rules={[{required: true, message: "Name"}]}
            onChanged={save}
            placeholder="Enter an item name"
        />

        <Form.Item label={"Dates"} name={["Dates"]} className="ant-form-item-without-validation">
            <TimelineDates
                form={form}
                isDisabled={!canEditDates}
                onDateChanged={onChangeDates}
                startDate={start && moment(start)}
                endDate={end && moment(end)}
                showTimeShiftInfo={false}
            />
        </Form.Item>

        <Form.Item form={form} name={["Location", "Id"]} label={"Location"}
                   className="ant-form-item-without-validation">
            <ContactSelect
                disabled={isOptionalFieldDisabled}
                selectedContactId={itemToEdit?.Location?.Id}
                onChange={(v) => {
                }}
                onChanged={save}
                disabledContactTypes={[0, 1]}
                groupSpaces={true}
                onOpenCreatePopup={() => {
                }}
                hideQuickCreate={true}
            />
        </Form.Item>

        <TagsSelect
            tagType={TagType.Department}
            disabled={isOptionalFieldDisabled}
            label={"Department"}
            name={"DepartmentTagId"}
            placeholder="Select a department"
            save={onChangeDepartment}
            form={form}
            closeOnChange={true}
            getValueProps={(tagId) => ({value: tagId ? [tagId.toString()] : []})}
            groups={[geTagsGroup("All Departments", departments)]}
        />

        <SelectWithFormItem
            label="Status"
            name={["StatusTag", "AccountTagId"]}
            showSearch={true}
            allowClear={true}
            style={{width: "100%"}}
            placeholder="Select a Status"
            options={statusOptions}
            onChanged={save}
            disabled={isOptionalFieldDisabled}
            filterOption={(input, option) => option.title.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
        />

        <TextAreaWithFormItem
            form={form}
            disabled={isOptionalFieldDisabled}
            name={["Description"]}
            label={"Details"}
            placeholder="Enter details"
            onChanged={save}
            useTextEditor={true}
        />

        <AuditFormItem item={itemToEdit} label="Created" timeProp="CreatedAt" userProp="Creator"/>

        <AuditFormItem item={itemToEdit} label="Last Updated" timeProp="UpdatedAt" userProp="Updater"/>
    </>);
};

export default InformationBlock;
