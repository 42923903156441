import React from "react";
import ChangedAtFilter from "../../Common/Tables/FilterBar/ChangedAtFilter/ChangedAtFilter";
import {SearchFilterRequirements, SearchFilterLabour} from "./SearchFilter";
import GroupBy from "./GroupBy";
import FilterBar from "../../Common/Tables/FilterBar";
import ItemsDateSelector from "./DateFilter/ItemsDateSelector";
import LabourDateSelector from "./DateFilter/LabourDateSelector";
import {RequirementType} from "../../../constants/constants";
import {useSelector} from "react-redux";

export const requirementsProjectsFilter = (selectedProjects = []) => {
    return (item) => selectedProjects.includes(item.Project.Id);
};

export const labourProjectsFilter = (selectedProjects = []) => {
    return (item) => selectedProjects.includes(item.Task?.Project.Id);
};

const RequirementsFilterBar = (props) => {
    const type = props.type;

    const selectedProjectsIds = useSelector((state) => state.projects.activeProjects).map((p) => p.Id);

    const projectFilterHandler =
        type === RequirementType.Item ? requirementsProjectsFilter(selectedProjectsIds) : labourProjectsFilter(selectedProjectsIds);

    const additionalFilters = [
        {
            handler: projectFilterHandler,
            key: selectedProjectsIds.join(","),
        },
    ];

    const DateFilter = type === RequirementType.Item ? ItemsDateSelector : LabourDateSelector;
    const SearchFilter = type === RequirementType.Item ? SearchFilterRequirements : SearchFilterLabour;
    return (
        <FilterBar
            {...props}
            additionalFilters={additionalFilters}
            filterComponents={[DateFilter, ChangedAtFilter, SearchFilter]}
            GroupByComponent={GroupBy}
        />
    );
};

export default RequirementsFilterBar;
