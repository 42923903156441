import React, {useState} from "react";
import SingleItemFilter from "../../../Common/Tables/FilterBar/SingleItemFilter";
import "./GroupBy.scss";

export const ContactGroupBy = Object.freeze({
    DefaultRoles: "Default Roles",
    DefaultGroups: "Default Groups",
});

const items = [
    {
        value: ContactGroupBy.DefaultRoles,
        title: ContactGroupBy.DefaultRoles,
    },
    {
        value: ContactGroupBy.DefaultGroups,
        title: ContactGroupBy.DefaultGroups,
    },
];

const GroupByFilter = ({setGroupBy}) => {
    const [selectedItem, setSelectedItem] = useState();

    function onChanged(selectedItem) {
        setGroupBy(selectedItem);
        setSelectedItem(selectedItem);
    }

    function getFilterText(selected = {title: ""}) {
        return `Group by ${selected.title}`;
    }

    return (
        <SingleItemFilter
            className="filter-group-by"
            selectedItem={selectedItem}
            items={items}
            onChanged={onChanged}
            getFilterText={getFilterText}
            hasReset={true}
        />
    );
};

export default GroupByFilter;
