import {createSlice} from "@reduxjs/toolkit";

const attachmentsSlice = createSlice({
    name: "attachments",
    initialState: {attachments: []},
    reducers: {
        setAttachments(state, action) {
            state.attachments = action.payload;
        },

    },
});

export const {setAttachments} = attachmentsSlice.actions;

export default attachmentsSlice.reducer;
